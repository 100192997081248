@use "../../../index.scss" as i;

.Service {
    position: relative;
    height: 500px;
    width: clamp(200px, 25vw, 300px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: 300ms;

    .service-image {
        // width: clamp(200px, 25vw, 300px);
        width: 300px;
        height: 300px;
        border-radius: 50% 50% 0 0;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    h3 {
        font-size: .7rem;
        margin: 5px 0px;
    }

    hr {
        width: clamp(200px, 25vw, 300px);
        border: 1px solid i.$tertiary_color;
    }

    p {
        font-size: .6rem;
    }

    .service-icon-container {
        position: absolute;
        width: 100%;
        height: 300px;
        background-color: i.$primary_color;
        border-radius: 50% 50% 0 0;
        display: flex;
        flex-direction: column;
        color: i.$secondary_color;
        justify-content: center;
        align-items: center;
        opacity: 0;
        text-decoration: none;

        p {
            font-size: .5rem;
        }

        &:hover,
        &:active {
            cursor: pointer;
            animation: appear 300ms linear forwards;
        }

        &:not(:hover) {
            animation: disappear 300ms linear forwards;
        }
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 15px gray;
    }
}

@keyframes disappear {
    0% {
        opacity: 1;
        box-shadow: 0 0 15px gray;
    }

    100% {
        opacity: 0;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}

@media only screen and (max-width: 769px) {
    .Service {
        width: 100%;
        height: fit-content;
        display: flex;

        // margin-top: 60px; //was 30px then 130px
        .service-image,
        .service-icon-container {
            width: 100%;
            height: auto;

            .service-icon {
                width: 50%;
                padding: 40px 0px 0px;
            }

            p {
                padding-bottom: 40px;
            }
        }


        &.visible {
            &.odd {
                animation: slideIn 1.5s ease 0s 1 normal forwards;
            }


            &.even {
                animation: slideInReverse 1.5s ease 0s 1 normal forwards;

            }
        }
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        bottom: 0px;
    }
}

@keyframes slideInReverse {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        bottom: 0px;
    }
}