@use '../../index.scss' as i;

.Navbar {
    @include i.primary_color_background;
    position: fixed;
    width: 100%;
    font-size: .8rem;
    // height: fit-content;
    height: 65px;
    top: -65px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    transition: top 0.3s;
    box-shadow: var(--bold-box-shadow);

    ul {
        margin: 20px 0px;
        list-style-type: none;
        display: flex;

        li {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                padding: 10px 20px;
                text-decoration: none;
                @include i.secondary_color_text;
                font-size: .45rem;
                transition: .5s;
                width: 105%;
                height: 50%;
                z-index: 1;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-top: 2px solid i.$secondary_color;
                    border-bottom: 2px solid i.$secondary_color;
                    // border: 2px solid i.$secondary_color;

                    transform: scaleY(2);
                    opacity: 0;
                    transition: .3s;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    // @include i.secondary_color_background;
                    transform: scale(0);
                    opacity: 0;
                    transition: .3s;
                    z-index: -1;
                }

                &.desktop-logo {

                    // background-color: red;
                    &:before,
                    &:after {
                        border: 0;
                    }
                }

                &:hover {
                    // @include i.primary_color_text;

                    &:before {
                        transform: scaleY(1);
                        opacity: 1;
                    }

                    &:after {
                        transform: scaleY(1);
                        opacity: 1;
                    }
                }
            }
            // padding: 0;
            &.mobile-logo,
            &.mobile-trigger {
                display: none;
            }

            img {
                width: 45px;
                margin: 0px 10px;
            }

        }
    }
}

@media only screen and (max-width: 769px) {
    .Navbar {
        height: 65px;
        top: -65px;

        ul {
            margin: 0;
            height: 100%;
            width: 100%;
            display: inline-block;

            li {
                transition: 300ms;

                &.mobile-logo {
                    float: right;
                }

                &.mobile-trigger {
                    float: left;
                }

                a {
                    height: 100%;
                }

                &.mobile-logo,
                &.mobile-trigger {
                    display: inline-block;
                    height: 100%;
                    // width: 40%;
                    @include i.primary_color_background;

                    // height: 65px;
                    a {

                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }

                &.item,
                .desktop-logo {
                    display: none;

                    &.is-open {
                        transition: 300ms;
                        display: block;
                        @include i.primary_color_background;
                        box-shadow: var(--bold-box-shadow);

                        a {
                            display: block;
                            width: 100%;
                            height: 65px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            &.desktop-logo {
                                display: none;
                            }
                        }
                    }
                }

                &.inner-li {
                    @include i.primary_color_background;
                    display: block;
                }
            }
        }
    }
}