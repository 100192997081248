@use '../../../index.scss' as i;

.Project {
    width: 400px;
    height: fit-content;
    color: black;
    text-decoration: none;
    position: relative;

    .date-cliend {
        box-sizing: border-box;
        @include i.tertiary_color_background;
        @include i.secondary_color_text;
        height: 60px;
        line-height: 60px;
        position: absolute;
        width: 0%;
        top: 240px;
        font-size: .6rem;
        text-align: left;
        padding-left: 20px;
        margin: 1px;
        opacity: 0;
        transition: 200ms;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    img {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border: 2px solid i.$tertiary_color;
        width: 100%;
        height: 300px;
    }

    p {
        font-size: .6rem;

        span {
            font-style: italic;
            @include i.primary_color_text;
            font-weight: 600;
        }
    }

    &:hover {
        img {
            transition: opacity .2s ease-in;
            opacity: 0.3;
        }

        .date-cliend {
            opacity: 1;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .Project {
        width: 100%;

        img {
            height: 80%;
        }

        &:hover {
        .date-cliend {
                opacity: 1;
                width: 100%;
                margin: 0;
                border: 0;
                // bottom: 40px;
                top: 65%;
                height: 30px;
                display: flex;
                align-items: center;
            }
        }
    }
}