@use "../../index.scss" as i;


.AboutSection {
    @include i.secondary_color_background;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 100px;

    .left-col {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            @include i.primary_color_text;
            font-size: 1.35rem;
            text-align: left;
            margin: 20px 0px;
        }

        div {
            font-size: .6rem;

            p {
                text-align: justify;
                padding-bottom: 20px;
            }
        }

        .buttons {
            margin: 50px auto 0px;
        }
    }

    .right-col {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
            background: linear-gradient(135deg, i.$primary_color , transparent, i.$primary_color);
            position: absolute;
            border-radius: 12px;
            height: 374px;
            // padding: 50px 0px;
        }

        img {
            rotate: (10deg);
            border-radius: 12px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border: 2px solid i.$tertiary_color;
            transition: 300ms;
            width: 377px;
            height: 377px;

            &:hover {
                rotate: (0deg);
            }
        }
    }
}

@media only screen and (max-width: 796px) {
    .AboutSection {
        scroll-margin-top: 65px;
        height: fit-content;
        padding: 0px 40px;
        grid-template-columns: 1fr;
        grid-template-rows: 3fr 2fr;

        h1 {
            display: flex;
            justify-content: center;
            padding: 50px 0px;
        }

        p {
            margin: 0px 0px 10px 0px;
        }

        .left-col {
            .buttons {
                width: 100%;
                height: fit-content;
                margin: 0;

                a {
                    display: inline-block;
                    padding: 15px;
                    margin-bottom: 0px;
                }
            }
        }

        .right-col {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: fit-content;
            margin: auto;

            >div,
            img {
                width: 50vw;
                height: 50vw;

                &.profile-pic-visible {
                    animation: toggleProfileRotate 1.5s ease 0s 1 normal forwards;
                }
            }

        }
    }

    * {
        &.visible {
            animation: aboutHeaderAnim 1.5s ease 0s 1 normal forwards;
        }
    }
}

@media only screen and (max-width: 350px) {
    .AboutSection {
        .right-col {

            >div,
            img {
                width: 60vw;
                height: 60vw;
            }
        }
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
    }
}

@keyframes aboutHeaderAnim {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        bottom: 0px;
    }
}

@keyframes toggleProfileRotate {
    0% {
        rotate: (10deg);
    }
    50% {
        rotate: (0deg);
    }
    100% {
        rotate: (10deg);
    }
}