@use "../../index.scss" as i;


.ServicesSection {
    @include i.tertiary_color_background;
    background-color: #9999998c;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 170px 1fr;
    padding: 0px 100px 50px;
    gap: 60px;
    scroll-margin-top: 65px;

    .services-headers-container {
        grid-column: span 3;
        display: flex;
        flex-direction: column;

        h1 {
            @include i.primary_color_text;
            font-size: 1.35rem;
            margin: 50px 0 0;
            height: fit-content;
        }

        h3 {
            font-size: .8rem;
            font-weight: 500;
        }
    }

    .col {
        display: flex;
        justify-content: center;
        align-items: flex-start;


        // p {
        //     font-size: .6rem;
        //     text-align: left;
        // }
    }

}

@media only screen and (max-width: 1200px) {
    .ServicesSection {
        height: fit-content;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        padding: 0px 25vw;
        row-gap: 30px;

        .services-headers-container {
            h1 {
                display: flex;
                justify-content: center;
                margin: 50px 0 0;
            }
        }

        .col {
            grid-column: span 3;
            margin-top: 0;
            
            &:nth-of-type(2) {
                margin-top: 20px;
            }
            &:nth-of-type(4) {
                margin-bottom: 50px;
            }
        }

    }
}