@use "../../index.scss" as i;


.GallerySection {
    @include i.secondary_color_background;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 170px 1fr 1fr 1fr;
    gap: 60px;
    scroll-margin-top: 65px;
    padding: 0px 100px 100px 100px;
    min-width: fit-content;

    >* {
        display: flex;
        justify-content: center;
        align-items: center
    }

    .gallery-headers-container {
        grid-column: span 3;
        display: flex;
        flex-direction: column;

        h1 {
            @include i.primary_color_text;
            font-size: 1.35rem;
            margin: 50px 0 0;
            height: fit-content;
        }

        h3 {
            font-size: .8rem;
            font-weight: 500;
        }
    }

}

@media only screen and (max-width: 1200px) {
    .GallerySection {
        min-width: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        // row-gap: 300px;
        padding: 0px 25vw;

        >div:nth-last-child(1) {
            margin-bottom: 100px;
        }

        .gallery-headers-container {
            display: block;
            width: 100%;
            padding: 0;
            margin-top: 50px;

            h1,
            h3 {
                margin: 0px -20px;
            }
        }

        h1 {
            display: flex;
            justify-content: center;
        }

        div {
            grid-column: span 1;
        }
    }
}