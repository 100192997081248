@use '../../index.scss' as i;

.RightsSection {
    @include i.primary_color_background;
    @include i.secondary_color_text;
    font-size: .4rem;
    padding-bottom: 5px;
    height: fit-content;

    // margin-bottom: 40px;
    padding: 10px 0;
}

@media screen and (max-width: 760px) {
    .RightsSection {
        margin-bottom: 40px;
    }
}