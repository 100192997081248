@use '../../../index.scss' as i;

.GetInTouchButton {
    padding: 20px;
    width: fit-content;
    margin: 0px 10px;
    border-radius: 8px;
    transition: 300ms;
    text-decoration: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @include i.tertiary_color_background;
    @include i.secondary_color_text;

    &:hover {
        @include i.primary_color_background;
        @include i.secondary_color_text;
    }
}