@use "../../index.scss" as i;


.RecommendationsSection {
    @include i.primary_color2_background;
    height: fit-content;
    min-height: 100vh;
    display: grid;
    gap: 60px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 170px 1fr;
    padding: 0px 100px;
    scroll-margin-top: 65px;

    .col {
        display: flex;
        align-items: flex-start;
        // padding-bottom: 250px;
        margin-top: 40px
    }

    h1 {
        @include i.secondary_color_text;
        font-size: 1.35rem;
        text-align: center;
        grid-column: span 3;
        margin: 100px 0px 0px;
        height: fit-content;
    }

}

@media only screen and (max-width: 769px) {
    .RecommendationsSection {
        height: fit-content;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 0;
        padding: 0px 15vw;
        padding-bottom: 0px;

        h1 {
            display: flex;
            justify-content: center;
            padding: 0px 20px;
            margin: 70px 0px;
        }

        .col {
            width: 100%;
            grid-column: span 3;
            padding-bottom: 0;
            width: 100%;
            // padding: 0px 40px;
            margin-top: 0;
            text-align: center;
            justify-content: center;
            height: fit-content;

            &:nth-of-type(1) {
                // margin-top: 100px;
            }
        }
    }
}