@use "../../index.scss" as i;


.FirstSection {
    @include i.secondary_color_background;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        @include i.primary_color_text;
        margin: 10px;
        font-size: 2rem;
        opacity: 0;
        animation: floatIn 2s ease-in 0ms forwards;

        >span {
            color: black;
            font-style: italic;
        }
    }

    h2 {
        opacity: 0;
        font-weight: 500;
        font-size: 0.6rem;
        margin: 0;
        animation: floatIn 2s ease-in 1100ms forwards;
    }

    h3 {
        opacity: 0;
        font-weight: 500;
        font-size: 0.8rem;
        margin-top: 10px;
        animation: floatIn 2s ease-in 2100ms forwards;
    }

    .buttons {
        position: absolute;
        @include i.primary_color_text;
        @include i.secondary_color_background;
        font-weight: 500;
        font-size: 0.5rem;
        margin: 0;
        bottom: 30px;
        padding: 5px;
        opacity: 0;
        animation: appearContent 2s ease-in 4000ms forwards;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        a {
            padding: 20px;
            width: fit-content;
            margin: 0px 10px;
            border-radius: 8px;
            transition: 300ms;
            text-decoration: none;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            &:nth-child(1) {
                border: 1px solid i.$tertiary_color;
                @include i.tertiary_color_text;
            }

            &:nth-child(2) {
                @include i.tertiary_color_background;
                @include i.secondary_color_text;
            }

            &:hover {
                @include i.primary_color_background;
                @include i.secondary_color_text;
            }
        }

    }

    .links {
        position: fixed;
        @include i.secondary_color_background;
        border: 1px solid i.$tertiary_color;
        right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 12px 0 0 12px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        z-index: 999;

        svg {
            filter: grayscale(1);
            height: fit-content;
            width: 30px;
            margin: 0px 10px;

            g {
                transition: 200ms;
            }

            &:hover {
                g {
                    fill: i.$primary_color;
                }
            }
        }
    }
}

@media only screen and (max-width: 769px) {
    .FirstSection {
        h3 {
            padding-bottom: 150px;
        }

        .links {
            max-height: 40px;
            bottom: 0;
            border-radius: 0px;
            flex-direction: row;
            width: 100%;
            gap: 5px;

            a {
                width: 10vw;
                max-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 100%;
                    padding: 15px 0px;
                    margin: 0px 5px;
                }
            }
        }

        .buttons {
            margin-bottom: 50px;
            font-size: .8rem;
            // scale: 1.5;
        }
    }
}

@keyframes floatIn {
    0% {
        opacity: 0;
        bottom: -20px;
    }

    100% {
        opacity: 1;
        bottom: 0;
    }
}

@keyframes appearContent {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}