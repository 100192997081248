@use '../../../index.scss' as i;

.Recommendation {
    @include i.secondary_color_text;
    min-height: 250px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    direction: rtl;

    .header {
        min-height: 104px;
        font-style: italic;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content {
        font-size: .6rem;
    }

    .author {
        font-size: .6rem;
        margin-top: 10px;
        font-weight: 600;
    }

    hr {
        border: 1px solid i.$tertiary_color;
        margin: 20px 0px;
    }
}

@media only screen and (max-width: 769px) {
    .Recommendation {
        height: fit-content;
        min-height: fit-content;
        margin-bottom: 70px;

        .header {
            min-height: 0;
        }
    }
}