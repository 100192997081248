@use "../../../index.scss" as i;

.ContactOption {
    @include i.secondary_color_text;
    font-size: .6rem;
    display: flex;
    width: fit-content;
    width: 365px;
    text-decoration: none;

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        filter: grayscale(1);
    }

    img {
        width: 60px;
        animation: pulse-animation 2s infinite;
    }

    .header-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 10px;
    }

    .header {
        text-align: left;
    }
}

@media only screen and (max-width: 769px) {
    .ContactOption {
        flex-direction: column;
        .header-content-container {
            // justify-content: center;
            .header{
                height: 14px;
            }
        }
    }
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.275);
        border-radius: 50%;
    }

    100% {
        border-radius: 50%;
        box-shadow: 0 0 0 10px i.$primary_color;
    }
}