@use "../../index.scss" as i;

.ContactMeSection {
    @include i.primary_color_background;
    @include i.secondary_color_text;
    padding: 100px 100px 5px 100px;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    scroll-margin-top: 65px;

    .left-col {
        display: flex;
        flex-direction: column;

        .reach-out {
            font-size: 1.35rem;
        }

        hr {
            border: 1px solid i.$tertiary_color;
            margin: 30px 20px;
        }

        p:nth-of-type(2) {
            font-size: .6rem;
        }
    }

    .right-col {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        .ContactOption {
            margin: 20px 10px;

            &:nth-last-child(1) {
                padding-bottom: 45px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .ContactMeSection {
        height: fit-content;
        grid-template-columns: 1fr;
        column-gap: 0;
        padding: 0;
        position: relative;

        p {
            padding: 40px 20px 0px;
        }

        .right-col {
            width: 100%;
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            min-height: fit-content;

            button {
                padding-bottom: 60px;
            }

            a {
                width: 100%;
                padding-left: 40px;
            }

            .ContactOption {
                padding : 0;
                // margin: 10px 10px;
                
                &:nth-of-type(2){
                    margin: 0;
                }

                &:nth-last-child(1) {
                    padding-bottom: 0px;
                }
            }
        }
    }
}